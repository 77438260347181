import { useGetUserRoles } from 'api/plants-management/users/useGetUserRoles';
import { UserRole } from 'features/plants-management/models/user.model';
import { FC, ReactNode } from 'react';

type RoleRequiredProps = Readonly<{
  roles : UserRole[];
  elseTemplate?: ReactNode;
  mode ?: 'whitelist' | 'blacklist';
}>;

const checkRoles = (roles : UserRole[], mode : 'whitelist' | 'blacklist', role ?: string) : boolean => {
  if (mode === 'whitelist') {
    return roles.includes(role as UserRole);
  } else {
    return !roles.includes(role as UserRole);
  }
};

/**
This component restricts of displaying specific content inside of it for specific User Roles.
@param {UserRole} roles - Array of roles which should be able / unable to see specific content.
@param {ReactNode} elseTemplate - Content which should be displayed when role requirements are not met.
@param {string} mode - whitelist or blacklist roles passed in roles array.
**/
const RoleRequired : FC<RoleRequiredProps> = ({
  roles,
  children,
  elseTemplate,
  mode = 'whitelist',
}) => {
  const { data } = useGetUserRoles();

  const hasUserRole = checkRoles(roles, mode, data?.role);

  return (
    <>
      {hasUserRole && children}
      {!hasUserRole && elseTemplate}
    </>
  );
};

export default RoleRequired;
