import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { UserRole } from 'features/plants-management/models/user.model';
import moment from 'moment-timezone';

export interface accountState {
  plantId: number;
  plantName: string;
  navbarOpened: boolean;
  timezone: string;
  autoTimezone: boolean;
  activeStartDate: string;
  activeEndDate: string;
  role : UserRole | undefined;
}

const currentDate = new Date();
const startDate = moment(currentDate).subtract(7, 'd').toDate();
startDate.setHours(0, 0, 0, 0);
const endDate = new Date();
endDate.setHours(23, 59, 59, 999);

const initialState: accountState = {
  plantId: 0,
  plantName: '',
  navbarOpened: false,
  autoTimezone: true,
  timezone: moment.tz.guess(true),
  activeStartDate: startDate.toISOString(),
  activeEndDate: endDate.toISOString(),
  role: undefined,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setPlant: (state, action) => {
      state.plantId = action.payload;
    },
    setPlantName: (state, action) => {
      state.plantName = action.payload;
    },
    setNavbarOpen: (state, action) => {
      state.navbarOpened = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setAutoTimezone: (state, action) => {
      state.autoTimezone = action.payload;
    },
    setActiveStartDate: (state, action) => {
      state.activeStartDate = action.payload;
    },
    setActiveEndDate: (state, action) => {
      state.activeEndDate = action.payload;
    },
    setUserRole: (state, action : PayloadAction<UserRole | undefined>) => {
      state.role = action.payload;
    },
  },
});

export const {
  setPlant,
  setPlantName,
  setNavbarOpen,
  setTimezone,
  setAutoTimezone,
  setActiveEndDate,
  setActiveStartDate,
  setUserRole,
} = accountSlice.actions;

export const selectPlantId = (state: RootState) => state.account.plantId;
export const selectPlantName = (state: RootState) => state.account.plantName;
export const selectNavbarOpen = (state: RootState) => state.account.navbarOpened;
export const selectTimezone = (state: RootState) => state.account.timezone;
export const selectAutoTimezone = (state: RootState) => state.account.autoTimezone;
export const selectStartDate = (state: RootState) => state.account.activeStartDate;
export const selectEndDate = (state: RootState) => state.account.activeEndDate;
export const selectUserRole = (state : RootState) => state.account.role;

export default accountSlice.reducer;
