import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import commonModalReducer from '../components/CommonModal/CommonModalSlice';
import universalModalReducer from '../components/UniversalModal/UniversalModalSlice';
import authorizationReducer from '../auth/store/AuthorizationSlice';
import accountReducer from '../store/accountSlice';
import plantsManagementReducer from '../features/plants-management/store/plantsManagementSlice';
import plantsAdminSummaryReducer from '../features/dashboard/components/Analytics/SensorsAndGateways/PlantsStatisticsTile/store/plantsAdminSummarySlice';
import reportSlice from 'features/reports-management/store/reportSlice';
import pulley2xBearingSlice from 'features/plants-management/components/AssetsAndAssetGroups/DataAnalyze/ApplicationTypeAnalyses/Pulley2xBearings/slice/pulley2xBearingSlice';
import channelsSlice from 'features/contracts-administration/store/channelsSlice';
import newReportSlice from 'features/new-reports/store/newReportSlice';
import newReportChannelPartnersSlice from 'features/contracts-administration/components/details/reports/create-report/store/newReportChannelPartnersSlice';
import warrantySlice from 'features/batteries&warranty/store/warrantySlice';
import fseSlice from 'features/fse/store/fseSlice';
import salesSlice from 'features/sales/store/salesSlice';

export const store = configureStore({
  reducer: {
    commonModal: commonModalReducer,
    universalModal: universalModalReducer,
    authorization: authorizationReducer,
    account: accountReducer,
    plantsManagement: plantsManagementReducer,
    plantsAdminSummary: plantsAdminSummaryReducer,
    report: reportSlice,
    pulley2xBearing: pulley2xBearingSlice,
    channel: channelsSlice,
    newReport: newReportSlice,
    newReportChannelPartners: newReportChannelPartnersSlice,
    warranty: warrantySlice,
    fse: fseSlice,
    sales: salesSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
