import React from 'react';
import { Grid } from '@mui/material';
import { LeftNavbar } from '../../LeftNavbar';

const LayoutContext = React.createContext(null);

type PropsTypes = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: PropsTypes) => {
  return (
    <LayoutContext.Provider value={null}>
      <Grid>
        <Grid item>
          <LeftNavbar>{children}</LeftNavbar>
        </Grid>
      </Grid>
    </LayoutContext.Provider>
  );
};

export default React.memo<PropsTypes>(MainLayout);
