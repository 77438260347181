import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CreatedLabel } from 'components/Labels/models/label.model';

export interface SalesState {
  fseLabels : CreatedLabel[];
  refetchFseList : boolean;
  currentFilteredLabel ?: CreatedLabel;
}

const initialState : SalesState = {
  fseLabels: [],
  refetchFseList: false,
};

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setFseLabels: (state, action) => {
      state.fseLabels = action.payload;
    },
    setRefetchFseList: (state, action) => {
      state.refetchFseList = action.payload;
    },
    setCurrentFilteredLabel: (state, action) => {
      state.currentFilteredLabel = action.payload;
    },
  },
});

export const { setFseLabels, setRefetchFseList, setCurrentFilteredLabel } = salesSlice.actions;

export const selectFseLabels = (state : RootState) => state.sales.fseLabels;
export const selectRefetchFseList = (state : RootState) => state.sales.refetchFseList;
export const selectCurrentFilteredLabel = (state : RootState) => state.sales.currentFilteredLabel;

export default salesSlice.reducer;
