import { FC, memo } from 'react';
import UserProfile from './UserProfile';
import { manuButtons } from '../manu-buttons';

interface ManageAccountDetailsProps {
  activeLink ?: string;
  firstName : string;
  lastName : string;
  email : string;
}

const ManageAccountDetails: FC<ManageAccountDetailsProps> = ({
  activeLink,
  firstName,
  lastName,
  email,
}) => {
  const getComponent = () => {
    if (activeLink === manuButtons[0].id)
      return <UserProfile firstName={firstName} lastName={lastName} email={email} />;
  };

  const component = getComponent();

  return <>{component}</>;
};

export default memo(ManageAccountDetails);
