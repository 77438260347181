import { useMsal } from '@azure/msal-react';
import { useAuthQuery } from 'api/queries';
import { UserRole } from 'features/plants-management/models/user.model';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { setUserRole } from 'store/accountSlice';

export const getUserRolesUrl = () => `users/roles`;

export type ChannelDetails = {
  channelId : number;
  channelName : string;
  role : string;
};
interface Response {
  email : string;
  role : UserRole;
  plants : { id : number; name : string }[];
}

export const convertToPlantRole = (role : string) : UserRole => {
  return role
    .split('_')
    .map((x) => `${x[0].toUpperCase()}` + x.slice(1))
    .join(' ') as UserRole;
};

const validRoles : UserRole[] = ['Super Administrator', 'Super Viewer', 'Fse'];

export const useGetUserRoles = (queryOptions?: UseQueryOptions<any, unknown, any, string>) => {
  const { instance } = useMsal();

  const dispatch = useDispatch();

  const logout = () => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
    };
    instance.logout(logoutRequest);
  };

  return useAuthQuery(getUserRolesUrl(), 0, {
    ...queryOptions,
    onSettled(data : Response, error) {
      if (error || !validRoles.includes(data.role)) logout();
    },
    select(response) {
      if (!response) {
        dispatch(setUserRole(undefined));
      } else {
        dispatch(setUserRole(convertToPlantRole(response.role)));
      }

      return {
        ...response,
        role: convertToPlantRole(response.role),
      };
    },
  }) as UseQueryResult<Response>;
};
