import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { MsalAuthenticationResult, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { msalInstance } from 'index';
import { ElementType, ReactNode, memo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { CustomNavigationClient } from './NavigationClient';

type Props = {
  children: ReactNode;
};

//can only be used in <MsalProvider>
const AuthRequired = ({ children }: Props) => {
  const { instance } = useMsal();
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  msalInstance.setNavigationClient(navigationClient);

  if (
    !window.location.href.includes('/dashboard') ||
    /^.*\/dashboard\?plantId=.*/.test(window.location.href)
  ) {
    localStorage.setItem('redirectionUrl', window.location.href);
  }

  const rememberedStorage = localStorage
    .getItem('redirectionUrl')
    ?.replace('www.', '')
    ?.replace(process.env.REACT_APP_DOMAIN as string, '');

  const remembered = rememberedStorage === '/' ? '/dashboard' : rememberedStorage;

  const rememberMeSSO = localStorage.getItem('domain_hint'); 

  const loginRequest: RedirectRequest = {
    scopes: [],
    redirectUri: '/dashboard',
    ...(rememberMeSSO &&
      rememberMeSSO !== 'undefined' && {
        extraQueryParameters: {
          domain_hint: JSON.parse(rememberMeSSO),
        },
      }),
  };

  const ErrorComponent: ElementType<MsalAuthenticationResult> = () => {
    instance.loginRedirect();
    return <></>;
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
      >
        {remembered ? (
          <>
            <Redirect to={remembered} />
            {children}
          </>
        ) : (
          <>{children}</>
        )}
      </MsalAuthenticationTemplate>
    </>
  );
};

export default memo(AuthRequired);
