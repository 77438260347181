import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ActiveStep,
  AssetGroupAndAsset,
  CreateGroup,
  ReportCategory,
  ReportType,
  RequiredFields,
  SelectedPlant,
  ShiftsPerDay,
  User,
  View,
  WeekendOperation,
  initReqFieldsState,
} from 'features/new-reports/models/reportsModels';
import { RootState } from 'app/store';
import { Template } from 'api/reports/useGetTemplates';
import { initTemplate } from '../templates/SelectTemplate';
import { RecurringReport } from 'api/channel-partners/reports/useGetChannelPartnerRecurringReports';

type StandardReportType = 'all' | 'unhealthy';

export interface ReportStateChannelPartners {
  view : View;
  reportCategory : ReportCategory;
  selectedRecurringReports : RecurringReport[];
  activeStep : ActiveStep;
  assets : AssetGroupAndAsset[];
  imageUrl : string;
  generateStandardReportType : StandardReportType;
  generateStandardReport : boolean;
  // new report
  reportType : ReportType;
  selectedPlant : SelectedPlant | null;
  selectedAssets : AssetGroupAndAsset[];
  customGroups : CreateGroup[];
  recipients : User[];
  audience : User[];
  unit : string;
  fileFormat : string;
  drawDescriptionFields : boolean;
  plantStructureHeadings : boolean;
  includeAssetImages : boolean;
  includeThreeAxisOnChar : boolean;
  reportName : string;
  reportFileName : string;
  selectedTemplate : Template;

  requiredFields : RequiredFields;
  templates : Template[];
  schedule : string;

  isReportEdit : boolean;
  language : string;
  selectedOption : string;
  customValue : string;
  weekendOperation : WeekendOperation;
  shiftsPerDay : ShiftsPerDay;
  plantUptime : number;
}

export const initialState : ReportStateChannelPartners = {
  view: 'reports',
  reportCategory: 'Standard',
  selectedRecurringReports: [],
  activeStep: 0,
  assets: [],
  imageUrl: `${process.env.REACT_APP_BLOB_IMAGE_STORAGE}/public/reports/plant_report_default_img.png`,
  generateStandardReportType: 'unhealthy',
  generateStandardReport: false,
  reportType: 'Standard',
  selectedPlant: null,
  selectedAssets: [],
  customGroups: [],
  recipients: [],
  audience: [],
  unit: 'metric',
  fileFormat: 'docx',
  drawDescriptionFields: false,
  plantStructureHeadings: false,
  includeAssetImages: false,
  includeThreeAxisOnChar: false,
  reportName: '',
  reportFileName: '',
  selectedTemplate: initTemplate,
  requiredFields: initReqFieldsState,
  templates: [],
  schedule: '',

  isReportEdit: false,
  language: 'EN',
  selectedOption: '7',
  customValue: '',
  weekendOperation: 'none',
  shiftsPerDay: '1x8',
  plantUptime: 0,
};

export const newReportChannelPartnersSlice = createSlice({
  name: 'newReportChannelPartners',
  initialState,
  reducers: {
    setView: (state, action : PayloadAction<View>) => {
      state.view = action.payload;
    },
    setReportCategory: (state, action : PayloadAction<ReportCategory>) => {
      state.reportCategory = action.payload;
    },
    setRecurringReports: (state, action : PayloadAction<RecurringReport[]>) => {
      state.selectedRecurringReports = action.payload;
    },
    setActiveStep: (state, action : PayloadAction<ActiveStep>) => {
      state.activeStep = action.payload;
    },
    setReportType: (state, action : PayloadAction<ReportType>) => {
      state.reportType = action.payload;
    },
    setSelectedPlant: (state, action : PayloadAction<SelectedPlant | null>) => {
      state.selectedPlant = action.payload;
    },
    setSelectedAssets: (state, action : PayloadAction<AssetGroupAndAsset[]>) => {
      state.selectedAssets = action.payload;
    },
    setCustomGroups: (state, action : PayloadAction<CreateGroup[]>) => {
      state.customGroups = action.payload;
    },
    setRecipients: (state, action : PayloadAction<User[]>) => {
      state.recipients = action.payload;
    },
    setAudience: (state, action : PayloadAction<User[]>) => {
      state.audience = action.payload;
    },
    setUnit: (state, action : PayloadAction<string>) => {
      state.unit = action.payload;
    },
    setFileFormat: (state, action : PayloadAction<string>) => {
      state.fileFormat = action.payload;
    },
    setDrawDescriptionFields: (state, action : PayloadAction<boolean>) => {
      state.drawDescriptionFields = action.payload;
    },
    setPlantStructureHeadings: (state, action : PayloadAction<boolean>) => {
      state.plantStructureHeadings = action.payload;
    },
    setIncludeAssetImages: (state, action : PayloadAction<boolean>) => {
      state.includeAssetImages = action.payload;
    },
    setIncludeThreeAxisOnChar: (state, action : PayloadAction<boolean>) => {
      state.includeThreeAxisOnChar = action.payload;
    },
    setReportName: (state, action : PayloadAction<string>) => {
      state.reportName = action.payload;
    },
    setReportFileName: (state, action : PayloadAction<string>) => {
      state.reportFileName = action.payload;
    },
    setSelectedTemplate: (state, action : PayloadAction<Template>) => {
      state.selectedTemplate = action.payload;
    },
    setAssets: (state, action : PayloadAction<AssetGroupAndAsset[]>) => {
      state.assets = action.payload;
    },
    setRequiredFields: (state, action : PayloadAction<RequiredFields>) => {
      state.requiredFields = action.payload;
    },
    setTemplates: (state, action : PayloadAction<Template[]>) => {
      state.templates = action.payload;
    },
    setSchedule: (state, action : PayloadAction<string>) => {
      state.schedule = action.payload;
    },
    setIsReportEdit: (state, action : PayloadAction<boolean>) => {
      state.isReportEdit = action.payload;
    },
    setLanguage: (state, action : PayloadAction<string>) => {
      state.language = action.payload;
    },
    setSelectedOption: (state, action : PayloadAction<string>) => {
      state.selectedOption = action.payload;
    },
    setCustomValue: (state, action : PayloadAction<string>) => {
      state.customValue = action.payload;
    },
    setWeekendOperation: (state, action : PayloadAction<WeekendOperation>) => {
      state.weekendOperation = action.payload;
    },
    setShiftsPerDay: (state, action : PayloadAction<ShiftsPerDay>) => {
      state.shiftsPerDay = action.payload;
    },
    setPlantUptime: (state, action : PayloadAction<number>) => {
      state.plantUptime = action.payload;
    },
    setGenerateStandardReportType: (state, action : PayloadAction<StandardReportType>) => {
      state.generateStandardReportType = action.payload;
    },
    setGenerateStandardReport: (state, action : PayloadAction<boolean>) => {
      state.generateStandardReport = action.payload;
    },
    setImageUrl: (state, action : PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
  },
});

export const {
  setView,
  setReportCategory,
  setRecurringReports,
  setActiveStep,
  setReportType,
  setSelectedPlant,
  setSelectedAssets,
  setCustomGroups,
  setRecipients,
  setAudience,
  setUnit,
  setFileFormat,
  setPlantStructureHeadings,
  setDrawDescriptionFields,
  setIncludeAssetImages,
  setIncludeThreeAxisOnChar,
  setReportName,
  setReportFileName,
  setSelectedTemplate,
  setAssets,
  setRequiredFields,
  setTemplates,
  setSchedule,
  setIsReportEdit,
  setLanguage,
  setSelectedOption,
  setCustomValue,
  setImageUrl,
  setGenerateStandardReportType,
  setGenerateStandardReport,
  setPlantUptime,
  setShiftsPerDay,
  setWeekendOperation,
} = newReportChannelPartnersSlice.actions;

export const selectView = (state : RootState) => state.newReportChannelPartners.view;
export const selectReportCategory = (state : RootState) =>
  state.newReportChannelPartners.reportCategory;
export const selectRecurringReports = (state : RootState) =>
  state.newReportChannelPartners.selectedRecurringReports;
export const selectActiveStep = (state : RootState) => state.newReportChannelPartners.activeStep;
export const selectReportType = (state : RootState) => state.newReportChannelPartners.reportType;
export const selectSelectedPlant = (state : RootState) =>
  state.newReportChannelPartners.selectedPlant;
export const selectSelectedAssets = (state : RootState) =>
  state.newReportChannelPartners.selectedAssets;
export const selectCustomGroups = (state : RootState) => state.newReportChannelPartners.customGroups;
export const selectRecipients = (state : RootState) => state.newReportChannelPartners.recipients;
export const selectAudience = (state : RootState) => state.newReportChannelPartners.audience;
export const selectUnit = (state : RootState) => state.newReportChannelPartners.unit;
export const selectFileFormat = (state : RootState) => state.newReportChannelPartners.fileFormat;
export const selectDrawDescriptionFields = (state : RootState) =>
  state.newReportChannelPartners.drawDescriptionFields;
export const selectPlantStructureHeadings = (state : RootState) =>
  state.newReportChannelPartners.plantStructureHeadings;
export const selectIncludeAssetImages = (state : RootState) =>
  state.newReportChannelPartners.includeAssetImages;
export const selectIncludeThreeAxisOnChar = (state : RootState) =>
  state.newReportChannelPartners.includeThreeAxisOnChar;
export const selectReportName = (state : RootState) => state.newReportChannelPartners.reportName;
export const selectReportFileName = (state : RootState) =>
  state.newReportChannelPartners.reportFileName;
export const selectSelectedTemplate = (state : RootState) =>
  state.newReportChannelPartners.selectedTemplate;
export const selectAssets = (state : RootState) => state.newReportChannelPartners.assets;
export const selectRequiredFields = (state : RootState) =>
  state.newReportChannelPartners.requiredFields;
export const selectTemplates = (state : RootState) => state.newReportChannelPartners.templates;
export const selectSchedule = (state : RootState) => state.newReportChannelPartners.schedule;
export const selectIsReportEdit = (state : RootState) => state.newReportChannelPartners.isReportEdit;
export const selectLanguage = (state : RootState) => state.newReportChannelPartners.language;
export const selectSelectedOption = (state : RootState) =>
  state.newReportChannelPartners.selectedOption;
export const selectCustomValue = (state : RootState) => state.newReportChannelPartners.customValue;
export const selectImageUrl = (state : RootState) => state.newReportChannelPartners.imageUrl;
export const selectGenerateStandardReportType = (state : RootState) =>
  state.newReportChannelPartners.generateStandardReportType;
export const selectGenerateStandardReport = (state : RootState) =>
  state.newReportChannelPartners.generateStandardReport;
export const selectWeekendOperation = (state : RootState) =>
  state.newReportChannelPartners.weekendOperation;
export const selectShiftsPerDay = (state : RootState) => state.newReportChannelPartners.shiftsPerDay;
export const selectPlantUptime = (state : RootState) => state.newReportChannelPartners.plantUptime;

export default newReportChannelPartnersSlice.reducer;
