import { Box, Divider } from '@mui/material';
import { useGetUserRoles } from 'api/plants-management/users/useGetUserRoles';
import { FC, memo } from 'react';
import ManageAccountDetails from './ManageAccountDetails';
import ManageAccountHeader from './ManageAccountHeader';

interface ManageAccountContentProps {
  activeLink ?: string;
  firstName : string;
  lastName : string;
  email : string;
}

const ManageAccountContent : FC<ManageAccountContentProps> = ({
  activeLink,
  firstName,
  lastName,
  email,
}) => {
  const { data: role } = useGetUserRoles();

  return (
    <>
      <ManageAccountHeader activeLink={activeLink} numberOfPlant={role?.plants.length ?? 0} />
      <Divider sx={{ mb: '1rem' }} />
      <Box ml='0.8rem'>
        <ManageAccountDetails
          activeLink={activeLink}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      </Box>
    </>
  );
};

export default memo(ManageAccountContent);
