import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AssetInConfigurableGroup } from '../models/assetInConfigurableGroup';
import { Plant } from '../models/selectPlant.model';
import { AssetGroupAndAsset } from '../models/selectAssetGroupAndAsset.model';
import { CreateGroup } from '../models/createGroups';
import { ConfigurableGroup } from 'features/plants-management/models/asset.model';

export interface ReportState {
  reportType : string;
  newReport : boolean;
  step : number;
  onConfGroupRowsSelectedArray : ConfigurableGroup[];
  onAllAssetsRowsSelectedArray : Array<AssetInConfigurableGroup>;
  selectedPlant : Plant[];
  selectedAsset : AssetGroupAndAsset[];
  reportName : string;
  fileFormat : string;
  drawDescriptionFields : boolean;
  plantStructureHeadings : boolean;
  includeAssetImages : boolean;
  customGroups : CreateGroup[];
  disabledStep : boolean;
  isGenerate : boolean;
  assetGroupsInSelectedPlant : unknown[];
  plants : Plant[];
  assets : AssetGroupAndAsset[];
  unit : string;
  triggerRefetch : boolean;
}

const initialState : ReportState = {
  reportType: 'standard',
  newReport: false,
  step: 0,
  onConfGroupRowsSelectedArray: [],
  onAllAssetsRowsSelectedArray: [],
  selectedPlant: [],
  selectedAsset: [],
  reportName: '',
  fileFormat: 'docx',
  drawDescriptionFields: false,
  plantStructureHeadings: false,
  includeAssetImages: false,
  customGroups: [],
  disabledStep: true,
  isGenerate: false,
  assetGroupsInSelectedPlant: [],
  plants: [],
  assets: [],
  unit: 'metric',
  triggerRefetch: false,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    chooseReport: (state, action) => {
      state.reportType = action.payload;
    },
    createReport: (state, action) => {
      state.newReport = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    addRowToConfGroupRowsSelectedArray: (state, action) => {
      state.onConfGroupRowsSelectedArray = state.onConfGroupRowsSelectedArray.concat([
        action.payload,
      ]);
    },
    deleteRowFromConfGroupRowsSelectedArray: (state, action) => {
      state.onConfGroupRowsSelectedArray = state.onConfGroupRowsSelectedArray.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    deleteAllRowFromConfGroupRowsSelectedArray: (state) => {
      state.onConfGroupRowsSelectedArray.length = 0;
    },

    addRowToAllAssetsRowsSelectedArray: (state, action) => {
      state.onAllAssetsRowsSelectedArray = state.onAllAssetsRowsSelectedArray.concat([
        action.payload,
      ]);
    },
    deleteRowFromAllAssetsRowsSelectedArray: (state, action) => {
      state.onAllAssetsRowsSelectedArray = state.onAllAssetsRowsSelectedArray.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    deleteAllRowFromAllAssetsRowsSelectedArray: (state) => {
      state.onAllAssetsRowsSelectedArray.length = 0;
    },
    setSelectedPlant: (state, action) => {
      state.selectedPlant = action.payload;
    },
    setSelectedAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
    setReportName: (state, action) => {
      state.reportName = action.payload;
    },
    setFileFormat: (state, action) => {
      state.fileFormat = action.payload;
    },
    setDrawDescriptionFields: (state, action) => {
      state.drawDescriptionFields = action.payload;
    },
    setPlantStructureHeadings: (state, action) => {
      state.plantStructureHeadings = action.payload;
    },
    setIncludeAssetImages: (state, action) => {
      state.includeAssetImages = action.payload;
    },
    setCustomGroups: (state, action) => {
      state.customGroups = action.payload;
    },
    setDisabledStep: (state, action) => {
      state.disabledStep = action.payload;
    },
    setGenerate: (state, action) => {
      state.isGenerate = action.payload;
    },
    setAssetGroupsInSelectedPlant: (state, action) => {
      state.assetGroupsInSelectedPlant = action.payload;
    },
    setPlants: (state, action) => {
      state.plants = action.payload;
    },
    setAssets: (state, action) => {
      state.assets = action.payload;
    },
    setUnit: (state, action) => {
      state.unit = action.payload;
    },
    setTriggerRefetch: (state, action) => {
      state.triggerRefetch = action.payload;
    },
  },
});

export const {
  chooseReport,
  createReport,
  setStep,
  addRowToConfGroupRowsSelectedArray,
  deleteRowFromConfGroupRowsSelectedArray,
  deleteAllRowFromConfGroupRowsSelectedArray,
  addRowToAllAssetsRowsSelectedArray,
  deleteRowFromAllAssetsRowsSelectedArray,
  deleteAllRowFromAllAssetsRowsSelectedArray,
  setSelectedPlant,
  setSelectedAsset,
  setReportName,
  setFileFormat,
  setDrawDescriptionFields,
  setPlantStructureHeadings,
  setIncludeAssetImages,
  setCustomGroups,
  setDisabledStep,
  setGenerate,
  setAssetGroupsInSelectedPlant,
  setPlants,
  setAssets,
  setUnit,
  setTriggerRefetch,
} = reportSlice.actions;

export const selectReportType = (state : RootState) => state.report.reportType;
export const selectNewReport = (state : RootState) => state.report.newReport;
export const selectStep = (state : RootState) => state.report.step;
export const selectOnConfGroupRowsSelectedArray = (state : RootState) =>
  state.report.onConfGroupRowsSelectedArray;
export const selectOnAllAssetsRowsSelectedArray = (state : RootState) =>
  state.report.onAllAssetsRowsSelectedArray;
export const selectSelectedPlant = (state : RootState) => state.report.selectedPlant;
export const selectSelectedAsset = (state : RootState) => state.report.selectedAsset;
export const selectReportName = (state : RootState) => state.report.reportName;
export const selectFileFormat = (state : RootState) => state.report.fileFormat;
export const selectDrawDescriptionFields = (state : RootState) => state.report.drawDescriptionFields;
export const selectPlantStructureHeadings = (state : RootState) =>
  state.report.plantStructureHeadings;
export const selectIncludeAssetImages = (state : RootState) => state.report.includeAssetImages;
export const selectCustomGroups = (state : RootState) => state.report.customGroups;
export const selectDisabledStep = (state : RootState) => state.report.disabledStep;
export const selectGenerate = (state : RootState) => state.report.isGenerate;
export const selectAssetGroupsInSelectedPlant = (state : RootState) =>
  state.report.assetGroupsInSelectedPlant;
export const selectPlants = (state : RootState) => state.report.plants;
export const selectAssets = (state : RootState) => state.report.assets;
export const selectUnit = (state : RootState) => state.report.unit;
export const selectTriggerRefetch = (state : RootState) => state.report.triggerRefetch;

export default reportSlice.reducer;
