import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface FseState {
  showMyPlants : boolean;
  fsePlants : string[];
}

const initialState : FseState = {
  showMyPlants: false,
  fsePlants: [],
};

export const fseSlice = createSlice({
  name: 'fse',
  initialState,
  reducers: {
    setShowMyPlants: (state, action : PayloadAction<boolean>) => {
      state.showMyPlants = action.payload;
    },
    setFsePlants: (state, action : PayloadAction<string[]>) => {
      state.fsePlants = action.payload;
    },
  },
});

export const { setShowMyPlants, setFsePlants } = fseSlice.actions;

export const selectShowMyPlants = (state : RootState) => state.fse.showMyPlants;
export const selectFsePlants = (state : RootState) => state.fse.fsePlants;

export default fseSlice.reducer;
